<template>
    <b-card-code no-body title="Create new report type">
        <b-row class="pb-2">
            <b-col md="6">
                <div class="px-2">
                <!-- form -->
                <validation-observer ref="reportTypeCreateForm">
                    <b-form
                    class="auth-register-form mt-2"
                    @submit.prevent="createReportType"
                    >
                    <!-- name -->
                    <b-form-group>
                        <validation-provider
                        #default="{ errors }"
                        name="name"
                        vid="name"
                        rules="required"
                        >
                        <b-form-input
                            id="name"
                            v-model="name"
                            name="name"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-button
                        variant="primary"
                        type="submit"
                        :disabled="btnDisabled"
                    >
                        Create report type
                    </b-button>
                    <div
                        v-if="loading"
                        class="d-flex justify-content-center mt-2"
                    >
                        <b-spinner variant="primary" label="Loading..." />
                    </div>
                    </b-form>
                </validation-observer>
                </div>
            </b-col>
        </b-row>
    </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BButton, BFormInput, BForm, BFormGroup, BRow, BCol, BInputGroup, BInputGroupAppend } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { BSpinner } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from 'vue-select'

export default {
    components: {
        BCardCode,
        BRow,
        BCol,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        ValidationProvider,
        ValidationObserver,
        BInputGroupAppend,
        BSpinner,
        vSelect
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            name: "",

            // validation
            required,
            email,
            btnDisabled: false,
            loading: false,
            isAvailableUsername: 1,
            errors: [],
            slugFlag: false,
            slugString: "",
        }
    },
    computed: {        
        passwordToggleIcon() {
            return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
        },
        cPasswordToggleIcon() {
            return this.cPasswordFieldType === "cPassword" ? "EyeIcon" : "EyeOffIcon";
        }
    },
    methods: {
        // Request to create report type
        createReportType() {
            let reportType = {
                "name": this.name
            }

            this.$refs.reportTypeCreateForm.validate().then(success => {
                if (success) {
                    this.$http.post("/api/auth/admin/report-types", reportType)
                    .then((res) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Report type successfully created",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });

                        // Reset form
                        this.name = ""
                    })
                    .catch((error) => {
                        let errorMsg = "There was an error!"
                        if(error.response.data != undefined && error.response.data.errors.name != undefined) {
                            errorMsg = error.response.data.errors.name[0]
                        }

                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: errorMsg,
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            });
        },
    },
}
</script>

<style scoped>
    option[disabled] { background-color: #D0D0D0; }
</style>

<style>
    .vs__selected {
        background-color: #59949c!important;
    }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
